import React, { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import Page from "../../Components/Page/Page";
import profileUser from "../../Assets/profileUser.png";
import homeIcon from "../../Assets/homeIcon.png";
import settings from "../../Assets/settings.png";
import Logo from "../../Assets/Logo.png";
import win from "../../Assets/win.png";
import timerIcon from "../../Assets/timerIcon.png";
import help from "../../Assets/help.png";
import Switch from "react-switch";
import ToggleButton from "../ToggleButton/ToggleButton";
import { useStopwatch } from "react-timer-hook";
import { useLocation, useNavigate } from "react-router-dom";
function Header({
  nav = "default",
  timerSwitch = false,
  seconds = null,
  minutes = null,
  timerDiff,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [timerVisible, setTimerVisible] = useState(true);
  // const {
  //   seconds,
  //   minutes,
  //   hours,
  //   days,
  //   isRunning,
  //   start,
  //   pause,
  //   reset,
  // } = useStopwatch({ autoStart: timerSwitch });

  const timerController = (timer) => {
    setTimerVisible(timer);
  };
  const leftNavHandler = (routeParam) => {

    const loc = location.pathname.split('/')[1];

    if (loc === 'game') {
      document.getElementById('quitGame')?.click();
    } else {
      navigate(routeParam);
    }
  }
  return (
    <header className={styles.header}>
      <div className={styles.topNav}>
        {nav !== "anonymous" && (
          <div className={styles.leftNav}>
            <div className={styles.navIconContainer} onClick={() => leftNavHandler('/')}>
              <img className={styles.navIcon} src={homeIcon}></img>
            </div>
            <div className={styles.navIconContainer} onClick={() => leftNavHandler('/profile')}>
              <img className={styles.navIcon} src={profileUser}></img>
            </div>
            <div className={styles.navIconContainer} onClick={() => leftNavHandler('/settings')}>
              <img className={styles.navIcon} src={settings}></img>
            </div>
          </div>
        )}
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={Logo}></img>
        </div>
        {nav !== "anonymous" && (
          <>
            {nav !== "game" ? (
              <div className={styles.rightNav}>
                <a className={styles.navIconContainer} href="/statistics">
                  <img className={styles.navIcon} src={win}></img>
                </a>
                <div className={styles.navIconContainer}>
                  <img className={styles.navIcon} src={help}></img>
                </div>
              </div>
            ) : (
              <div className={styles.timerNav}>
                <div className={styles.timerIconContainer}>
                  <img className={styles.timerIcon} src={timerIcon}></img>
                </div>
                <div className={styles.timerContainer}>
                  {timerVisible && (
                    <>
                      {/* <span>{minutes}</span>:<span>{seconds}</span> */}
                      <span>{timerDiff}</span>
                    </>
                  )}
                </div>
                <ToggleButton timerController={timerController} />
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.headerTitleContainer}>
        <hr className={styles.leftRule} />
        <div className={styles.headerTitle}>A WORD GAME</div>
        <hr className={styles.rightRule} />
      </div>
    </header>
  );
}

export default Header;
