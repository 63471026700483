import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "./Game.module.scss";
import Page from "../../Components/Page/Page";
import Header from "../../Components/Header/Header";
import backIcon from "../../Assets/backIcon.png";
import quitIcon from "../../Assets/quitIcon.png";
import guessIcon from "../../Assets/guessIcon.png";
import { asyncLocalStorage } from "../../Utils/LocalStorage";
import { apiDomain } from "../../Utils/Constants";
import Swal from "sweetalert2";
import moment from "moment";

function Game() {
  const [keyboardUpdateCounter, setKeyboardUpdateCounter] = useState(0);
  const [keyboardRow1Keys, setKeyboardRow1Keys] = useState([
    { alphabet: "Q", colorIndex: 0 },
    { alphabet: "W", colorIndex: 0 },
    { alphabet: "E", colorIndex: 0 },
    { alphabet: "R", colorIndex: 0 },
    { alphabet: "T", colorIndex: 0 },
    { alphabet: "Y", colorIndex: 0 },
    { alphabet: "U", colorIndex: 0 },
    { alphabet: "I", colorIndex: 0 },
    { alphabet: "O", colorIndex: 0 },
    { alphabet: "P", colorIndex: 0 },
  ]);
  const [keyboardRow2Keys, setKeyboardRow2Keys] = useState([
    { alphabet: "A", colorIndex: 0 },
    { alphabet: "S", colorIndex: 0 },
    { alphabet: "D", colorIndex: 0 },
    { alphabet: "F", colorIndex: 0 },
    { alphabet: "G", colorIndex: 0 },
    { alphabet: "H", colorIndex: 0 },
    { alphabet: "J", colorIndex: 0 },
    { alphabet: "K", colorIndex: 0 },
    { alphabet: "L", colorIndex: 0 },
  ]);
  const [keyboardRow3Keys, setKeyboardRow3Keys] = useState([
    { alphabet: "Z", colorIndex: 0 },
    { alphabet: "X", colorIndex: 0 },
    { alphabet: "C", colorIndex: 0 },
    { alphabet: "V", colorIndex: 0 },
    { alphabet: "B", colorIndex: 0 },
    { alphabet: "N", colorIndex: 0 },
    { alphabet: "M", colorIndex: 0 },
  ]);

  const getColorIndex = (colorName) => {
    switch (colorName) {
      case "green":
        return 3;
      case "orange":
        return 2;
      case "grey":
        return 1;
      default:
        return 0;
    }
  };

  const updateKeyboardRow = (keyboardarr, setArr, item) => {
    const arr = keyboardarr;
    const count = keyboardUpdateCounter + 1;
    for (var i = 0; i < arr.length; i++) {
      if (
        arr[i].alphabet === item.character.toUpperCase() &&
        arr[i].colorIndex < getColorIndex(item.color_name)
      ) {
        arr[i].colorIndex = getColorIndex(item.color_name);
      }
      setArr(arr);
      setKeyboardUpdateCounter(count);
    }
  };

  const changeKeyColor = (item) => {
    if (
      keyboardRow1Keys.some((i) =>
        i.alphabet.includes(item.character.toUpperCase())
      )
    ) {
      updateKeyboardRow(keyboardRow1Keys, setKeyboardRow1Keys, item);
    } else if (
      keyboardRow2Keys.some((i) =>
        i.alphabet.includes(item.character.toUpperCase())
      )
    ) {
      updateKeyboardRow(keyboardRow2Keys, setKeyboardRow2Keys, item);
    } else if (
      keyboardRow3Keys.some((i) =>
        i.alphabet.includes(item.character.toUpperCase())
      )
    ) {
      updateKeyboardRow(keyboardRow3Keys, setKeyboardRow3Keys, item);
    }
  };
  const [customerID, setCustomerID] = useState(null);
  const wordLength = useParams().wordlength;
  const rowNumbers = wordLength === "5" ? 6 : wordLength === "6" ? 8 : 10;
  const gameLevel = useParams().level;
  const gameType = useParams().gametype;
  const [rowFilled, setRowFilled] = useState(-1);
  const [correctRow, setCorrectRow] = useState(-1);
  const [row1, setRow1] = useState([]);
  const [row2, setRow2] = useState([]);
  const [row3, setRow3] = useState([]);
  const [row4, setRow4] = useState([]);
  const [row5, setRow5] = useState([]);
  const [row6, setRow6] = useState([]);
  const [row7, setRow7] = useState([]);
  const [row8, setRow8] = useState([]);
  const [row9, setRow9] = useState([]);
  const [row0, setRow0] = useState([]);
  const [keyRow, setKeyRow] = useState([]);
  const [gameData, setGameData] = useState(null);
  const [apiMsg, setApiMsg] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [timerDiff, setTimerDiff] = useState("00:00:00");
  let [timerID, setTimerID] = useState(null);

  const [loadingState, setLoadingState] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    asyncLocalStorage.getItem("userData").then((data) => {
      setTimeout(() => {
        document.getElementById("pageID").focus();
      }, 1);

      if (data !== null) {
        const userID = JSON.parse(data).data.user_details[0].id;
        setCustomerID(userID);
        const gamePlayParams = `customer_id=${userID}&word_length=${wordLength}&game_level=${gameLevel}&game_type=${gameType}`;
        callGamePlay(gamePlayParams);
        setLoadingState(2);
      } else {
        setLoadingState(0);
      }
    });
  }, []);



  const rowSelector = (rowIndex) => {
    switch (rowIndex) {
      case 0:
        return { row: row0, setRow: setRow0 };
      case 9:
        return { row: row9, setRow: setRow9 };
      case 8:
        return { row: row8, setRow: setRow8 };
      case 7:
        return { row: row7, setRow: setRow7 };
      case 6:
        return { row: row6, setRow: setRow6 };
      case 5:
        return { row: row5, setRow: setRow5 };
      case 4:
        return { row: row4, setRow: setRow4 };
      case 3:
        return { row: row3, setRow: setRow3 };
      case 2:
        return { row: row2, setRow: setRow2 };
      default:
        return { row: row1, setRow: setRow1 };
    }
  };

  const setRowData = (rowNumber, rowInfo, correctStatus = false) => {
    const { setRow } = rowSelector(rowNumber);
    setRow(rowInfo);
    if (correctStatus === false) {
      setRowFilled(rowNumber);
      if (rowFilled + 1 === rowNumbers - 1 || rowNumber === rowNumbers - 1) {
        clearInterval(timerID);
      }
    } else {
      setCorrectRow(rowNumber);
      clearInterval(timerID);
      Swal.fire({
        title: apiMsg && apiMsg !== '' ? apiMsg : "Congratulations! You're Correct",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#89e855",
        cancelButtonColor: "#ff6272",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed === true) {
          navigate("/", { replace: true });
        }
      });
    }
    for (var i = 0; i < rowInfo.length; i++) {
      changeKeyColor(rowInfo[i]);
    }
  };

  const callGamePlay = (paramString) => {
    fetch(`${apiDomain}/api/gamePlay?${paramString}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        ///////self timer ////////////
        setStartDate(new Date());
        timerID = setInterval(() => {
          let selfTimerStart = moment(startDate);
          let selfTimerEnd = moment(new Date()).add(data.data.time_elapsed, 'seconds');
          let selfTimerDiff = selfTimerEnd.diff(selfTimerStart);
          let f = moment.utc(selfTimerDiff).format("HH:mm:ss");
          setTimerDiff(f);
        }, 1000);
        if (
          data.data &&
          (data.data.history !== null || data.data.history.length > 0)
        ) {
          for (var i = 0; i < data.data.history.length; i++) {
            setRowData(
              i,
              data.data.history[i].row_info,
              data.data.history[i].correct
            );
          }
        }
        setTimerID(timerID);
        return setGameData(data);
      });
  };

  ///////redirection logic
  if (loadingState === 1) {
    return <h1>Loading</h1>;
  } else if (loadingState === 0) {
    navigate("/login", { replace: true });
  }
  ///////redirection logic

  const rowEndApiCall = (rowEndWord) => {
    fetch(
      `${apiDomain}/api/gamePlay?gameId=${gameData.data.gameId}&word=${rowEndWord}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setApiMsg(data?.message);
        if (data.status === 1) {
          const { setRow } = rowSelector(rowFilled + 1);
          if (
            data?.data?.current.length === 0 ||
            data?.data?.current.row_clear === true
          ) {
            const arr = [];
            setRow(arr);
          } else {
            setRowData(
              data?.data?.current?.row_number,
              data?.data?.current?.row_info,
              data?.data?.current?.correct
            );
          }
        }
      }, []);
  };

  const onQuit = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#89e855",
      cancelButtonColor: "#ff6272",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed === true) {
        fetch(`${apiDomain}/api/gameQuit?customer_id=${customerID}&game_id=${gameData.data.gameId}`)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.status === 1) {
              setApiMsg(data.message);
              setTimeout(() => {
                navigate("/", { replace: true });
              }, 3000);
            }
          });
      }
    });
  };

  const onletterPress = (letter) => {
    if (correctRow !== -1) {
      return false;
    }
    setApiMsg(null);
    const { row, setRow } = rowSelector(rowFilled + 1);
    if (letter === "Clear") {
      row.pop();
      setRow([...row]);
    } else {
      if (row.length < wordLength) {
        row.push({ character: letter, color_name: "white" });
        setRow([...row]);
      }
    }
  };

  const onLockPress = () => {
    const { row } = rowSelector(rowFilled + 1);
    if (row.length < wordLength) {
      Swal.fire({
        text: `Your guess must contain ${wordLength} characters`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#89e855",
        cancelButtonColor: "#ff6272",
        confirmButtonText: "OK!",
      });
    } else {
      let rowWord = "";
      row.map((item) => {
        rowWord += item.character;
      });
      rowEndApiCall(rowWord);
    }
    const keyRowArr = keyRow.concat(row);
    setKeyRow([...keyRowArr]);
  };

  const getCellColor = (colorName) => {
    switch (colorName) {
      case "grey":
        return "gameCellGrey";
      case "orange":
        return "gameCellYellow";
      case "green":
        return "gameCellGreen";
      case 1:
        return "gameCellGrey";
      case 2:
        return "gameCellYellow";
      case 3:
        return "gameCellGreen";
      default:
        return "";
    }
  };

  const getBoardRow = (length, rowIndex) => {
    const boardRow = [];
    for (var i = 0; i < wordLength; i++) {
      let active = false;
      if (
        rowFilled + 1 === rowIndex &&
        ((rowSelector(rowIndex).row[i - 1] && !rowSelector(rowIndex).row[i]) ||
          (!rowSelector(rowIndex).row[i] && i === 0))
      ) {
        active = true;
      }
      boardRow.push(
        <div className={styles.gameCellWrapper}>
          <label
            className={`${styles.gameCell} ${active === true ? styles.active : null
              } ${styles[
              `${getCellColor(rowSelector(rowIndex)?.row[i]?.color_name)}`
              ]
              } ${wordLength > 6 ? styles.gameCellWidthSmall : styles.gameCellWidth
              }`}
          >
            {rowSelector(rowIndex) && rowSelector(rowIndex).row[i]
              ? rowSelector(rowIndex).row[i].character
              : ""}
          </label>
        </div>
      );
    }
    return boardRow;
  };

  const getGameBoardRows = (length) => {
    const board = [];
    for (var i = 0; i < rowNumbers; i++) {
      board.push(
        <div className={styles.gameBoardRow}>{getBoardRow(length, i)}</div>
      );
    }
    return board;
  };

  const handleKeyPress = (e) => {
    const keyboardMerge = [
      ...keyboardRow1Keys,
      ...keyboardRow2Keys,
      ...keyboardRow3Keys,
    ];
    if (keyboardMerge.some((i) => i.alphabet.includes(e.key.toUpperCase()))) {
      onletterPress(e.key.toUpperCase());
    } else if (e.key === "Backspace") {
      onletterPress("Clear");
    } else if (e.key === "Enter") {
      onLockPress();
    } else if (e.key === "Escape") {
      onQuit();
    }
  };
  const gameboardHeight = window.innerHeight - 367;
  return (
    <Page onKeyPressHandler={handleKeyPress}>
      <Header nav="game" timerSwitch timerDiff={timerDiff} />
      <section className={styles.gameSection}>
        <div className={styles.gameBoard} style={{ 'height': `${gameboardHeight}px` }}>{getGameBoardRows()}</div>
        <div className={styles.keyboard}>
          {apiMsg && (
            <div className={styles.apiMsg}>
              <label>{apiMsg}</label>
            </div>
          )}
          <div className={styles.keyboardRow1}>
            {keyboardRow1Keys.map((element) => {
              return (
                <div className={styles.keyWrapper}>
                  <button
                    className={`${styles.key} ${styles[`${getCellColor(element.colorIndex)}`]
                      }`}
                    onClick={() => {
                      onletterPress(element.alphabet);
                    }}
                  >
                    {element.alphabet}
                  </button>
                </div>
              );
            })}
          </div>
          <div className={styles.keyboardRow2}>
            {keyboardRow2Keys.map((element) => {
              return (
                <div className={styles.keyWrapper}>
                  <button
                    className={`${styles.key} ${styles[`${getCellColor(element.colorIndex)}`]
                      }`}
                    onClick={() => {
                      onletterPress(element.alphabet);
                    }}
                  >
                    {element.alphabet}
                  </button>
                </div>
              );
            })}
          </div>
          <div className={styles.keyboardRow3}>
            {keyboardRow3Keys.map((element) => {
              return (
                <div className={styles.keyWrapper}>
                  <button
                    className={`${styles.key} ${styles[`${getCellColor(element.colorIndex)}`]
                      }`}
                    onClick={() => {
                      onletterPress(element.alphabet);
                    }}
                  >
                    {element.alphabet}
                  </button>
                </div>
              );
            })}
            <div className={styles.keyWrapper}>
              <div
                className={styles.delKey}
                onClick={() => {
                  onletterPress("Clear");
                }}
              >
                <div className={styles.delKeyIconContainer}>
                  <img className={styles.delKeyIcon} src={backIcon} />
                </div>
                <div className={styles.delKeyTitle}>DEL</div>
              </div>
            </div>
          </div>
          <div className={styles.playBtnRow}>
            {gameType === "1" && <div className={styles.lastRowKey} id="quitGame" onClick={onQuit}>
              <div className={styles.quitKeyIconContainer}>
                <img className={styles.quitKeyIcon} src={quitIcon} />
              </div>
              <div className={styles.quitKeyTitle}>QUIT</div>
            </div>}
            <div
              className={styles.lastRowKey}
              onClick={() => {
                onLockPress();
              }}
            >
              <div className={styles.guessKeyIconContainer}>
                <img className={styles.guessKeyIcon} src={guessIcon} />
              </div>
              <div className={styles.guessKeyTitle}>GUESS</div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default Game;
