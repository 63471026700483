import { useState, useRef, useEffect } from "react";
import styles from "./Register.module.scss";
import Page from "../../Components/Page/Page";
import profilePicIcon from "../../Assets/profilePicIcon.png";
import Header from "../../Components/Header/Header";
import { Separator, SubmitButton } from "../../Components/globalComponents";
import { useNavigate } from "react-router-dom";
import { asyncLocalStorage } from "../../Utils/LocalStorage";
import { validEmail, apiDomain } from "../../Utils/Constants";

function Register() {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profilePicNewUrl, setProfilePicNewUrl] = useState(null);
  const [profilePicNewFile, setProfilePicNewFile] = useState(undefined);
  const [loadingState, setLoadingState] = useState(1);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState();
  const imageFileInput = useRef(null);

  useEffect(() => {
    asyncLocalStorage.getItem("userData").then((data) => {
      if (data === null) {
        setLoadingState(2);
      } else {
        setLoadingState(0);
      }
    });
  }, []);

  if (loadingState === 1) {
    return <h1>Loading</h1>;
  } else if (loadingState === 0) {
    navigate("/", { replace: true });
  }

  const handleClick = () => {
    imageFileInput.current.click();
  };

  const cancelProfilePicNew = () => {
    setProfilePicNewUrl(null);
  };

  const handleProfilePicNewChange = (event) => {
    const fileUploaded = event.target.files[0];
    setProfilePicNewFile(fileUploaded);
    setProfilePicNewUrl(URL.createObjectURL(fileUploaded));
  };

  const registerSubmit = () => {
    const formData = new FormData();
    if (email === "") {
      setErrorMsg("Please enter valid Email ID");
    } else if (!validEmail.test(email)) {
      setErrorMsg("Please enter valid Email ID");
    } else if (mobile === "" || mobile === null) {
      setErrorMsg("Please enter your Mobile number");
    } else if (password === "") {
      setErrorMsg("Please enter Password");
    } else if (confirmPassword !== password) {
      setErrorMsg("Password and confirm password do not match");
    } else {
      setErrorMsg(null);

      formData.append("email", email);
      formData.append("password", password);
      formData.append("name", displayName);
      formData.append("phone", mobile);
      if (profilePicNewFile) {
        formData.append("photo", profilePicNewFile);
      }

      fetch(`${apiDomain}/user/register`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status === 1) {
            navigate("/login", { replace: true });
          } else if (data.status === 0) {
            setErrorMsg(data.message);
          }
        }, []);
    }
  };

  return (
    <Page>
      <Header nav="anonymous" />
      <section className={styles.registerSection}>
        <div className={styles.title}>REGISTER</div>
        <div className={styles.registerForm}>
          <div className={styles.profilePicField}>
            <img
              className={styles.profilePicUploadIcon}
              src={
                profilePicNewUrl !== null ? profilePicNewUrl : profilePicIcon
              }
            />
            <input
              type="file"
              ref={imageFileInput}
              onChange={handleProfilePicNewChange}
              style={{ display: "none" }}
            />
            {profilePicNewUrl === null ? (
              <label
                className={styles.profilePicUploadLabel}
                onClick={handleClick}
              >
                Select Profile Picture
              </label>
            ) : (
              <label
                className={styles.profilePicUploadLabel}
                onClick={cancelProfilePicNew}
              >
                Cancel
              </label>
            )}
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.textField}
              type="text"
              value={displayName}
              placeholder="Display Name"
              onChange={(e) => {
                setDisplayName(e.target.value);
              }}
            />
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.textField}
              type="text"
              value={email}
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.textField}
              type="text"
              value={mobile}
              placeholder="Mobile NO"
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.textField}
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.textField}
              type="password"
              value={confirmPassword}
              placeholder="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <Separator />
          <SubmitButton
            title="REGISTER"
            icon="loginBtnIcon"
            onClickHandler={registerSubmit}
          />
          <div className={styles.loginLinkContainer}>
            <a className={styles.loginLink} href="/login">
              SIGN IN
            </a>
          </div>
          {errorMsg && <p>{errorMsg}</p>}
        </div>
      </section>
    </Page>
  );
}

export default Register;
