import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Settings.module.scss";
import Page from "../../Components/Page/Page";
import Header from "../../Components/Header/Header";
import { Separator, SubmitButton } from "../../Components/globalComponents";
import Switch from "react-switch";
import { asyncLocalStorage } from "../../Utils/LocalStorage";

function Settings({ title = "SETTINGS" }) {
  const [gameType, setGameType] = useState(1);
  const [level, setLevel] = useState(5);
  const [difficulty, setDifficulty] = useState(1);
  const [timerSwitch, setTimerSwitch] = useState(true);
  //redirection Logic
  const [loadingState, setLoadingState] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    asyncLocalStorage.getItem("userData").then((data) => {
      if (data !== null) {
        setLoadingState(2);
      } else {
        setLoadingState(0);
      }
    });
  }, []);

  if (loadingState === 1) {
    return <h1>Loading</h1>;
  } else if (loadingState === 0) {
    navigate("/login", { replace: true });
  }
  ///////////////////////////////

  return (
    <Page>
      <Header />
      <section className={styles.settingsSection}>
        <div className={styles.title}>{title}</div>
        <div className={styles.settingsLabel}>GAME TYPE</div>
        <div className={styles.settingsOptionWrapper}>
          <div
            onClick={() => {
              setGameType(1);
            }}
            className={`${styles.settingsOption} ${
              gameType === 1 ? styles.activeOption : null
            }`}
          >
            UNLIMITED
          </div>
          <div
            onClick={() => {
              setGameType(2);
            }}
            className={`${styles.settingsOption} ${
              gameType === 2 ? styles.activeOption : null
            }`}
          >
            DAILY
          </div>
        </div>
        <Separator />
        <div className={styles.settingsLabel}>LEVEL</div>
        <div className={styles.settingsOptionWrapper}>
          <div
            onClick={() => {
              setLevel(5);
            }}
            className={`${styles.settingsOption} ${
              level === 5 ? styles.activeOption : null
            }`}
          >
            5 LETTER
          </div>
          <div
            onClick={() => {
              setLevel(6);
            }}
            className={`${styles.settingsOption} ${
              level === 6 ? styles.activeOption : null
            }`}
          >
            6 LETTER
          </div>
          <div
            onClick={() => {
              setLevel(7);
            }}
            className={`${styles.settingsOption} ${
              level === 7 ? styles.activeOption : null
            }`}
          >
            7 LETTER
          </div>
        </div>
        <Separator />
        <div className={styles.settingsLabel}>DIFFICULTY</div>
        <div className={styles.settingsOptionWrapper}>
          <div
            onClick={() => {
              setDifficulty(1);
            }}
            className={`${styles.settingsOption} ${
              difficulty === 1 ? styles.activeOption : null
            }`}
          >
            BASIC
          </div>
          <div
            onClick={() => {
              setDifficulty(2);
            }}
            className={`${styles.settingsOption} ${
              difficulty === 2 ? styles.activeOption : null
            }`}
          >
            INTERMEDIATE
          </div>
          <div
            onClick={() => {
              setDifficulty(3);
            }}
            className={`${styles.settingsOption} ${
              difficulty === 3 ? styles.activeOption : null
            }`}
          >
            ADVANCED
          </div>
          <div
            onClick={() => {
              setDifficulty(4);
            }}
            className={`${styles.settingsOption} ${
              difficulty === 4 ? styles.activeOption : null
            }`}
          >
            PRO
          </div>
        </div>
        <Separator />
        <div className={styles.settingsLabel}>Timer</div>
        <div className={styles.timerWrapper}>
          <Switch
            checked={timerSwitch}
            onChange={() => {
              setTimerSwitch(!timerSwitch);
            }}
            handleDiameter={40}
            offColor="#8dc73f"
            onColor="#8dc73f"
            offHandleColor="#dddedf"
            onHandleColor="#dddedf"
            height={50}
            width={122}
            borderRadius={6}
            activeBoxShadow="0px 0px 1px 2px #fffc35"
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 18,
                  color: "white",
                  paddingRight: 2,
                }}
              >
                OFF
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "white",
                  paddingRight: 2,
                }}
              >
                ON
              </div>
            }
            className="react-switch"
            id="small-radius-switch"
          />
        </div>
        <a
          className={styles.submitBtnWrapper}
          href={`/game/${level}/${difficulty}/${gameType}/${timerSwitch}`}
        >
          <SubmitButton
            title="START"
            icon="loginBtnIcon"
            onClickHandler={null}
          />
        </a>
      </section>
    </Page>
  );
}

export default Settings;
