import { useState, useRef, useEffect } from "react";
import styles from "./Profile.module.scss";
import Page from "../../Components/Page/Page";
import profilePicIcon from "../../Assets/profilePicIcon.png";
import editIcon from "../../Assets/editIcon.png";
import backIcon from "../../Assets/backIcon.png";

import Header from "../../Components/Header/Header";
import { Separator, SubmitButton } from "../../Components/globalComponents";
import { useNavigate } from "react-router-dom";
import { asyncLocalStorage } from "../../Utils/LocalStorage";
import { apiDomain } from "../../Utils/Constants";

function Profile() {
  const [editDisplayName, setEditDisplayName] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editTicker, setEditTicker] = useState(false);
  const [displayName, setDisplayName] = useState(null);
  const [ticker, setTicker] = useState("Arizen");
  const [password, setPassword] = useState("");
  //   const [confirmPassword, setConfirmPassword] = useState("");
  const [profilePicNewUrl, setProfilePicNewUrl] = useState(null);
  const [profilePicNewFile, setProfilePicNewFile] = useState(undefined);
  const [userData, setUserData] = useState(null);
  const [apiErrorMsg, setApiErrorMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  //   const [loadingState, setLoadingState] = useState(1);
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    asyncLocalStorage.getItem("userData").then((data) => {
      // console.log("userData by UC in asyncLocalStorage", JSON.parse(data));
      if (data !== null) {
        fetch(
          `${apiDomain}/user/view?id=${JSON.parse(data).data.user_details[0].id
          }`
        )
          .then((response) => {
            // console.log("response by UC in profile", response);
            return response.json();
          })
          .then((responsedata) => {
            // console.log("data by UC in profile", responsedata);
            if (responsedata.status === 1) {
              setUserData(responsedata);
            } else if (responsedata.status === 0) {
              setUserData(responsedata);
              setApiErrorMsg(responsedata.message);
            }
          }, []);
      } else {
        navigate("/login", { replace: true });
      }
    });
  }, []);

  if (userData === null) {
    return <h1>Loading...</h1>;
  } else {
    // console.log("userData by UC1", userData);
  }

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const cancelProfilePicNew = () => {
    setProfilePicNewUrl(null);
    setProfilePicNewFile(undefined);
  };

  const handleProfilePicNewChange = (event) => {
    const fileUploaded = event.target.files[0];
    setProfilePicNewFile(fileUploaded);
    setProfilePicNewUrl(URL.createObjectURL(fileUploaded));
  };

  const proileSubmit = () => {
    const formData = new FormData();
    formData.append("id", userData?.data ? userData.data[0].id : null);
    // console.log("image by UC", profilePicNewFile);
    if (editDisplayName) {
      if (!displayName || displayName === "") {
        setErrorMsg("Display Name cannot be empty");
        return false;
      } else {
        formData.append("name", displayName);
      }
    }

    if (editTicker) {
      if (!ticker || ticker === "") {
        setErrorMsg("Ticker cannot be empty");
        return false;
      } else {
        formData.append("ticker", ticker);
      }
    }

    if (editPassword) {
      if (!password || password === "") {
        setErrorMsg("Password cannot be empty");
        return false;
      } else {
        formData.append("password", password);
      }
    }

    if (profilePicNewFile) {
      formData.append("photo", profilePicNewFile);
    }

    if (errorMsg === null) {
      fetch(`${apiDomain}/user/edit`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          if (responseData.status === 1) {
            window.location.reload();
          } else if (responseData.status === 0) {
            setErrorMsg(responseData.message);
          }
        }, []);
    }
  };

  const OnLogout = () => {
    asyncLocalStorage.removeItem("userData").then(() => {
      navigate("/login");
    });
  };

  const cancelEditDisplayName = () => {
    setEditDisplayName(false);
    setDisplayName(null);
  };

  const enableEditDisplayName = () => {
    setEditDisplayName(true);
    setDisplayName(
      userData?.data && userData?.data[0]?.name ? userData.data[0].name : ""
    );
  };

  const enableEditTicker = () => {
    setEditTicker(true);
    setTicker(
      userData?.data && userData?.data[0]?.ticker ? userData.data[0].ticker : ""
    );
  };

  const cancelEditPassword = () => {
    setEditPassword(false);
    setPassword(null);
  };
  return (
    <Page>
      <Header />
      <section className={styles.profileSection}>
        <div className={styles.title}>Profile</div>
        <div className={styles.profileForm}>
          <div className={styles.profilePicField}>
            <img
              className={styles.profilePicUploadIcon}
              src={
                profilePicNewUrl !== null
                  ? profilePicNewUrl
                  : userData?.data &&
                    userData.data[0].photo !== "" &&
                    userData.data[0].photo !== null
                    ? userData?.data[0]?.photo
                    : profilePicIcon
              }
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleProfilePicNewChange}
              style={{ display: "none" }}
            />
            {profilePicNewUrl === null ? (
              <label
                className={styles.profilePicUploadLabel}
                onClick={handleClick}
              >
                Select Profile Picture
              </label>
            ) : (
              <label
                className={styles.profilePicUploadLabel}
                onClick={cancelProfilePicNew}
              >
                Cancel
              </label>
            )}
          </div>
          <Separator />
          <div className={styles.formRow}>
            <div className={styles.editRowContainer}>
              {!editDisplayName ? (
                <>
                  <div className={styles.editRow}>
                    <label className={styles.editRowLabel}>Display Name</label>
                    <div>
                      {userData?.data && userData?.data[0]?.name
                        ? userData.data[0].name
                        : ""}
                    </div>
                  </div>
                  <img
                    className={styles.editIcon}
                    onClick={() => enableEditDisplayName()}
                    src={editIcon}
                  />
                </>
              ) : (
                <>
                  <input
                    className={styles.textField}
                    type="text"
                    value={displayName}
                    placeholder="Display Name"
                    onChange={(e) => {
                      setDisplayName(e.target.value);
                    }}
                  />
                  <img
                    className={styles.editIcon}
                    onClick={() => cancelEditDisplayName()}
                    src={backIcon}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.editRowContainer}>
              {!editPassword ? (
                <>
                  <div className={styles.editRow}>
                    <label className={styles.editRowLabel}>
                      Change Password
                    </label>
                    <div>*******</div>
                  </div>
                  <img
                    className={styles.editIcon}
                    onClick={() => setEditPassword(true)}
                    src={editIcon}
                  />
                </>
              ) : (
                <>
                  <input
                    className={styles.textField}
                    type="password"
                    value={password ? password : ""}
                    placeholder="Set New Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <img
                    className={styles.editIcon}
                    onClick={() => cancelEditPassword()}
                    src={backIcon}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.editRowContainer}>
              {!editTicker ? (
                <>
                  <div className={styles.editRow}>
                    <label className={styles.editRowLabel}>Ticker</label>
                    <div>
                      {userData?.data && userData?.data[0]?.ticker
                        ? userData.data[0].ticker
                        : ""}
                    </div>
                  </div>
                  <img
                    className={styles.editIcon}
                    onClick={() => enableEditTicker()}
                    src={editIcon}
                  />
                </>
              ) : (
                <>
                  <input
                    className={styles.textField}
                    type="text"
                    value={ticker}
                    placeholder="Ticker"
                    onChange={(e) => {
                      setTicker(e.target.value);
                    }}
                  />
                  <img
                    className={styles.editIcon}
                    onClick={() => setEditTicker(false)}
                    src={backIcon}
                  />
                </>
              )}
            </div>
          </div>
          <Separator />
          <SubmitButton
            title="UPDATE"
            icon="updateBtnIcon"
            onClickHandler={proileSubmit}
          />
          <Separator />
          <SubmitButton
            title="LOG OUT"
            icon="updateBtnIcon"
            onClickHandler={OnLogout}
          />
        </div>
      </section>
    </Page>
  );
}

export default Profile;
