import styles from "./globalComponents.module.scss";
import heartIcon from "../Assets/heartIcon.png";
// import heartIcon from "../Assets/heartIcon.png";
import loginBtnIcon from "../Assets/loginBtnIcon.png";
import updateBtnIcon from "../Assets/updateBtnIcon.png";

export function Separator() {
  return (
    <div className={styles.separatorContainer}>
      <img className={styles.separator} src={heartIcon}></img>
    </div>
  );
}

export function SubmitButton({
  title = "",
  icon = "loginBtnIcon",
  onClickHandler = null,
}) {
  const getIconSrc = () => {
    switch (icon) {
      case "loginBtnIcon":
        return loginBtnIcon;
      case "updateBtnIcon":
        return updateBtnIcon;
      default:
        return null;
    }
  };
  return (
    <div className={styles.submitBtnRow} onClick={onClickHandler}>
      <div className={styles.submitBtnIconContainer}>
        <img className={styles.submitBtnIcon} src={getIconSrc()} />
      </div>
      <div className={styles.submitBtn}>{title}</div>
    </div>
  );
}
