import styles from "./Page.module.scss";

const Page = (props) => {
  const { onKeyPressHandler = null } = props;
  return (
    <div
      className={styles.pageContainer}
      onKeyDown={onKeyPressHandler ? onKeyPressHandler : null}
      tabIndex={onKeyPressHandler ? "0" : -1}
      id="pageID"
    >
      {props.children}
    </div>
  );
};

export default Page;
