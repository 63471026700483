import { useState, useRef, useEffect } from "react";
import styles from "./Statistics.module.scss";
import Page from "../../Components/Page/Page";
import Header from "../../Components/Header/Header";
import profilePicIcon from "../../Assets/profilePicIcon.png";
import { Separator, SubmitButton } from "../../Components/globalComponents";
import { apiDomain } from "../../Utils/Constants";
import { useNavigate } from "react-router-dom";
import { asyncLocalStorage } from "../../Utils/LocalStorage";

function Statistics() {
  const [gameType, setGameType] = useState("unlimited");
  const [overview, setOverview] = useState(5);
  const [winningStreak, setWinningStreak] = useState(5);
  const [guessDistribution, setGuessDistribution] = useState(5);
  const [difficulty, setDifficulty] = useState("basic");
  const [timerSwitch, setTimerSwitch] = useState(true);
  const [userStatistics, setUserStatistics] = useState(null);
  const [apiErrorMsg, setApiErrorMsg] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    asyncLocalStorage.getItem("userData").then((data) => {
      if (data !== null) {
        ///////////fetching statistics data////////////////
        fetch(
          `${apiDomain}/api/statistics?id=${
            JSON.parse(data).data.user_details[0].id
          }`
        )
          .then((response) => {
            // console.log("response by UC in statistics", response);
            return response.json();
          })
          .then((responsedata) => {
            // console.log("data by UC in statistics", responsedata);
            if (responsedata.status === 1) {
              setUserStatistics(responsedata);
            } else if (responsedata.status === 0) {
              setUserStatistics(responsedata);
              setApiErrorMsg(responsedata.message);
            }
          }, []);
        /////////////// fetching user data///////////////
        fetch(
          `${apiDomain}/user/view?id=${
            JSON.parse(data).data.user_details[0].id
          }`
        )
          .then((response) => {
            // console.log("response by UC in profile", response);
            return response.json();
          })
          .then((responsedata) => {
            // console.log("data by UC in profile", responsedata);
            if (responsedata.status === 1) {
              setUserData(responsedata);
            } else if (responsedata.status === 0) {
              setUserData(responsedata);
              setApiErrorMsg(responsedata.message);
            }
          }, []);
      } else {
        navigate("/login", { replace: true });
      }
    });
  }, []);

  if (userStatistics === null || userData === null) {
    return <h1>Loading...</h1>;
  } else {
    // console.log("userData by UC1", userStatistics);
  }

  const getOverviewData = () => {
    switch (overview) {
      case 7:
        return userStatistics.data.overview["7-letters"];
      case 6:
        return userStatistics.data.overview["6-letters"];
      default:
        return userStatistics.data.overview["5-letters"];
    }
  };

  const getWinningStreakData = () => {
    switch (winningStreak) {
      case 7:
        return userStatistics.data["winning-streak"]["7-letters"];
      case 6:
        return userStatistics.data["winning-streak"]["6-letters"];
      default:
        return userStatistics.data["winning-streak"]["5-letters"];
    }
  };
  const getGuessDistributionData = () => {
    switch (guessDistribution) {
      case 7:
        return userStatistics.data["guess-distribution"]["7-letters"];
      case 6:
        return userStatistics.data["guess-distribution"]["6-letters"];
      default:
        return userStatistics.data["guess-distribution"]["5-letters"];
    }
  };

  return (
    <Page>
      <Header />
      <section className={styles.statisticsSection}>
        <div className={styles.title}>MY STATS</div>
        <Separator />
        <div className={styles.profilePicSection}>
          <img
            className={styles.profilePic}
            src={
              userData?.data &&
              userData.data[0].photo !== "" &&
              userData.data[0].photo !== null
                ? userData?.data[0]?.photo
                : profilePicIcon
            }
          />
          <div className={styles.userNameWrapper}>
            <label className={styles.userName}>
              {userData?.data && userData?.data[0]?.ticker
                ? userData.data[0].name
                : ""}
            </label>
            <label className={styles.userName}>
              {userData?.data && userData?.data[0]?.ticker
                ? userData.data[0].ticker
                : ""}
            </label>
          </div>
        </div>
        <Separator />
        <div className={styles.statisticsOptionWrapper}>
          <div
            onClick={() => {
              setOverview(5);
            }}
            className={`${styles.statisticsOption} ${
              overview === 5 ? styles.activeOption : null
            }`}
          >
            5 LETTER
          </div>
          <div
            onClick={() => {
              setOverview(6);
            }}
            className={`${styles.statisticsOption} ${
              overview === 6 ? styles.activeOption : null
            }`}
          >
            6 LETTER
          </div>
          <div
            onClick={() => {
              setOverview(7);
            }}
            className={`${styles.statisticsOption} ${
              overview === 7 ? styles.activeOption : null
            }`}
          >
            7 LETTER
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <div className={styles.headerColumn}>GP</div>
            <div className={styles.headerColumn}>W</div>
            <div className={styles.headerColumn}>L</div>
            <div className={styles.headerColumn}>Q</div>
            <div className={styles.headerColumn}>%</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.column}>
              {userStatistics?.data?.overview ? getOverviewData().gp : null}
            </div>
            <div className={styles.column}>
              {userStatistics?.data?.overview ? getOverviewData().w : null}
            </div>
            <div className={styles.column}>
              {userStatistics?.data?.overview ? getOverviewData().l : null}
            </div>
            <div className={styles.column}>
              {userStatistics?.data?.overview ? getOverviewData().q : null}
            </div>
            <div className={styles.column}>
              {userStatistics?.data?.overview ? getOverviewData().per : null}
            </div>
          </div>
        </div>
        <Separator />
        <div className={styles.tableTitle}>WINNING STREAK</div>
        <div className={styles.statisticsOptionWrapper}>
          <div
            onClick={() => {
              setWinningStreak(5);
            }}
            className={`${styles.statisticsOption} ${
              winningStreak === 5 ? styles.activeOption : null
            }`}
          >
            5 LETTER
          </div>
          <div
            onClick={() => {
              setWinningStreak(6);
            }}
            className={`${styles.statisticsOption} ${
              winningStreak === 6 ? styles.activeOption : null
            }`}
          >
            6 LETTER
          </div>
          <div
            onClick={() => {
              setWinningStreak(7);
            }}
            className={`${styles.statisticsOption} ${
              winningStreak === 7 ? styles.activeOption : null
            }`}
          >
            7 LETTER
          </div>
        </div>
        <div className={styles.table2}>
          <div className={styles.tableRow}>
            <div className={styles.headerColumn}>CURRENT</div>
            <div className={styles.headerColumn}>LONGEST</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.column}>
              {userStatistics?.data["winning-streak"]
                ? getWinningStreakData().current
                : null}
            </div>
            <div className={styles.column}>
              {userStatistics?.data["winning-streak"]
                ? getWinningStreakData().longest
                : null}
            </div>
          </div>
        </div>
        <Separator />
        <div className={styles.tableTitle}>GUESS DISTRIBUTION</div>
        <div className={styles.statisticsOptionWrapper}>
          <div
            onClick={() => {
              setGuessDistribution(5);
            }}
            className={`${styles.statisticsOption} ${
              guessDistribution === 5 ? styles.activeOption : null
            }`}
          >
            5 LETTER
          </div>
          <div
            onClick={() => {
              setGuessDistribution(6);
            }}
            className={`${styles.statisticsOption} ${
              guessDistribution === 6 ? styles.activeOption : null
            }`}
          >
            6 LETTER
          </div>
          <div
            onClick={() => {
              setGuessDistribution(7);
            }}
            className={`${styles.statisticsOption} ${
              guessDistribution === 7 ? styles.activeOption : null
            }`}
          >
            7 LETTER
          </div>
        </div>
        <div className={styles.table3}>
          <div className={styles.tableRow}>
            <div className={styles.headerColumn}>Guess</div>
            <div className={styles.headerColumn}># WINS</div>
            <div className={styles.headerColumn}>%</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.column}>
              {userStatistics?.data["guess-distribution"]
                ? getGuessDistributionData().guess
                : null}
            </div>
            <div className={styles.column}>
              {userStatistics?.data["guess-distribution"]
                ? getGuessDistributionData().wins
                : null}
            </div>
            <div className={styles.column}>
              {userStatistics?.data["guess-distribution"]
                ? getGuessDistributionData().per
                : null}
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default Statistics;
