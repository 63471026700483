import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Register from "./Container/Register/Register";
import Login from "./Container/Login/Login";
import Profile from "./Container/Profile/Profile";
import Settings from "./Container/Settings/Settings";
import Statistics from "./Container/Statistics/Statistics";
import Game from "./Container/Game/Game";
import "./Global.module.scss";
import "./fonts/CoveredByYourGrace-Regular.ttf";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/register" exact={true} element={<Register />} />
        <Route path="/login" exact={true} element={<Login />} />
        <Route path="/profile" exact={true} element={<Profile />} />
        <Route
          path="/settings"
          exact={true}
          element={<Settings title="SETTINGS" />}
        />
        <Route path="/statistics" exact={true} element={<Statistics />} />
        <Route
          exact={true}
          path="/game/:wordlength/:level/:gametype/:timerswitch"
          element={<Game />}
        />
        <Route
          path="/"
          exact={true}
          element={<Settings title="WELCOME TO LINGOLEER" />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
