import React, { useState } from "react";
import styles from "./ToggleButton.module.scss";

const ToggleButton = ({ timerController }) => {
  const [timer, setTimer] = useState(true);
  const handleTimer = () => {
    setTimer(!timer);
    timerController(!timer);
  };
  return (
    <div className={styles.container} onClick={handleTimer}>
      <div className={styles.toggle}>
        <div className={!timer ? styles.toggleHandle : styles.noDisplay}></div>
        {timer && "ON"}
      </div>
      <div className={styles.toggle}>
        <div className={!timer ? styles.noDisplay : styles.toggleHandle}></div>
        {!timer && "OFF"}
      </div>
    </div>
  );
};

export default ToggleButton;
