import { useState, useRef, useEffect } from "react";
import styles from "./Login.module.scss";
import Page from "../../Components/Page/Page";
import Header from "../../Components/Header/Header";
import facebookIcon from "../../Assets/facebookIcon.png";
import instagramIcon from "../../Assets/instagramIcon.png";
import twitterIcon from "../../Assets/twitterIcon.png";
import { Separator, SubmitButton } from "../../Components/globalComponents";
import { FacebookProvider, Login } from "react-facebook";
import { useNavigate } from "react-router-dom";
import { asyncLocalStorage } from "../../Utils/LocalStorage";
import { apiDomain, validEmail } from "../../Utils/Constants";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState();
  const [loadingState, setLoadingState] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    asyncLocalStorage.getItem("userData").then((data) => {
      if (data === null) {
        setLoadingState(2);
      } else {
        setLoadingState(0);
      }
    });
  }, []);

  if (loadingState === 1) {
    return <h1>Loading</h1>;
  } else if (loadingState === 0) {
    navigate("/", { replace: true });
  }

  const fbOnClick = (response) => {
    console.log(response);
    const formData = new FormData();
    formData.append("email", response.profile.email);
    formData.append("name", response.profile.name);
    formData.append("id", response.profile.id);

    fetch(`${apiDomain}/user/facebook_login`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 1) {
          asyncLocalStorage
            .setItem("userData", JSON.stringify(data))
            .then(() => {
              navigate("/", { replace: true });
            });
        } else if (data.status === 0) {
          setErrorMsg(data.message);
        }
      }, []);
    // console.error("incomplete fb implementation", response);
  };

  const loginSubmit = () => {
    const formData = new FormData();
    if (email === "") {
      setErrorMsg("Please enter valid Email ID");
    } else if (!validEmail.test(email)) {
      setErrorMsg("Please enter valid Email ID");
    } else if (password === "") {
      setErrorMsg("Please enter Password");
    } else {
      setErrorMsg(null);

      formData.append("email", email);
      formData.append("password", password);

      fetch(`${apiDomain}/user/email_login`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status === 1) {
            asyncLocalStorage
              .setItem("userData", JSON.stringify(data))
              .then(() => {
                navigate("/", { replace: true });
              });
          } else if (data.status === 0) {
            setErrorMsg(data.message);
          }
        }, []);
    }
  };

  return (
    <Page>
      <Header nav="anonymous" />
      <section className={styles.loginSection}>
        <div className={styles.description}>
          Lingoleer is based on the famous wordle game with 5,6 and 7 letter
          variations with varying degree of difficulty from Easy to Expert.
          Choose between unlimted and "social share" where entire planet gets
          same word for 24 hours.
        </div>
        <div className={styles.title}>HAVE FUN</div>
        <div className={styles.loginForm}>
          <div className={styles.formRow}>
            <input
              className={styles.textField}
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className={styles.formRow}>
            <input
              className={styles.textField}
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <SubmitButton
            title="SIGN IN"
            icon="loginBtnIcon"
            onClickHandler={loginSubmit}
          />
          {errorMsg && <p>{errorMsg}</p>}
          <div className={styles.registerLinksContainer}>
            <a className={styles.registerLinks} href="/register">
              Register
            </a>
            <a className={styles.registerLinks} href="#">
              Forgot Password
            </a>
          </div>
          <Separator />
          <div className={styles.socialMediaContainer}>
            <div className={styles.socialLoginTitle}>Or sign-in with</div>
            <div className={styles.socialLoginBtnContainer}>
              {/* <FacebookProvider appId="583797216634988"> */}
              <FacebookProvider appId="601445177640416" >
                <Login
                  scope='email'
                  fields={['name', 'email']}
                  onCompleted={fbOnClick}
                  onError={fbOnClick}
                >
                  {({ loading, handleClick, error, data }) => (
                    <>
                      <img
                        onClick={handleClick}
                        className={styles.socialLoginBtn}
                        src={facebookIcon}
                      />
                      {loading && <span>Loading...</span>}
                    </>
                  )}
                </Login>
              </FacebookProvider>
              <img className={styles.socialLoginBtn} src={instagramIcon} />
              <img className={styles.socialLoginBtn} src={twitterIcon} />
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default LoginPage;
